<template>
  <div class="contact_box">
    <div class="page_center">
      <div class="page_title">联系我们 · CONTACT US</div>
      <div class="description">留下您的联系方式，我们会尽快联系您</div>
    </div>
    <div class="contact_form">
      <div class="contact_info">
        <div class="input_box">
          <input v-model="nickName" type="text" placeholder="请填写您的姓名" />
        </div>
        <div class="input_box">
          <input type="text" placeholder="请填写您的联系电话" v-model="phone" max="11" />
        </div>
        <div class="input_box">
          <input type="text" placeholder="请填写企业名称" v-model="companyName" />
        </div>
        <div class="input_box">
          <input type="text" placeholder="联系人微信" v-model="wx" />
        </div>
      </div>
      <div class="remarks_box">
        <textarea
          placeholder="请简单描述您的需求"
          maxlength="125"
          v-model="needDesc"
        ></textarea>
      </div>
      <button class="btn" @click="onSubmit">立即开始</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nickName: "",
      phone: "",
      companyName: "",
      wx: "",
      needDesc: "",
    };
  },
  methods: {
    onSubmit() {
      if (this.nickName === "") {
        this.$prompt("请填写您的姓名");
        return;
      }
      if (this.phone === "") {
        this.$prompt("请填写您的联系电话");
        return;
      }
      if (this.phone.length !== 11) {
        this.$prompt("请填写正确的联系电话");
        return;
      }
      if (this.companyName === "") {
        this.$prompt("请填写企业名称");
        return;
      }
      let httpData = {
        nickName: this.nickName,
        phone: this.phone,
        companyName: this.companyName,
      };
      if (this.wx !== "") {
        httpData.wx = this.wx;
      }
      if (this.needDesc !== "") {
        httpData.needDesc = this.needDesc;
      }
      this.$http.post("api/user_need/submit", httpData).then((res) => {
        this.$alert("提交成功！我们会尽快与您联系。", {}, () => {
          this.nickName = "";
          this.phone = "";
          this.wx = "";
          this.companyName = "";
          this.needDesc = "";
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact_box {
  height: 661px;
  padding-top: 80px;
  background-color: #fff;

  .description {
    margin-top: 40px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    line-height: 28px;
  }

  .contact_form {
    width: 1020px;
    margin: 60px auto 0;

    .contact_info {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .input_box {
      width: 240px;
      padding-left: 24px;
      border: 1px solid #e5e5e5;

      input {
        width: 100%;
        border: 0;
        outline-style: none;
        font-size: 14px;
        line-height: 60px;

        &::-webkit-input-placeholder {
          color: #999999;
        }
      }

      &.select_box {
        position: relative;
        color: #333333;
        cursor: pointer;

        .current_select {
          font-size: 14px;
          line-height: 60px;
        }

        ::after {
          content: "";
          position: absolute;
          right: 25px;
          top: 50%;
          width: 18px;
          height: 11px;
          transform: translate(0, -50%);
          background-image: url("../assets/ic_pull_down.png");
        }
      }
    }
    .remarks_box {
      width: 100%;
      height: 140px;
      padding: 21px 24px;
      margin-top: 20px;
      border: 1px solid #e5e5e5;

      textarea {
        width: 100%;
        height: 100%;
        border: 0;
        resize: none;
        outline-style: none;
        font-size: 14px;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-input-placeholder {
          color: #999999;
        }
      }
    }

    .btn {
      display: block;
      margin: 60px auto 0;
      width: 400px;
      height: 60px;
      background-color: #5842f2;
      font-size: 14px;
      color: #ffffff;
    }
  }
}

.page_title {
  font-size: 32px;
  color: #333333;
  text-align: center;
}
</style>
