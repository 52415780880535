<template>
  <div>
    <z-header></z-header>
    <div class="min_width banner_image_box">
      <img src="../assets/img_system.png" alt="" />
    </div>

    <div class="e_commerce">
      <div class="page_center">
        <div class="case_title">电商案例展示 · E-COMMERCE ETSHOP CASE SHOW</div>
        <div class="case_info_box">
          <div class="tab_box">
            <span
              :class="{ active: item.objId == devTypeId }"
              v-for="(item, index) in devTypeList"
              :key="index"
              @click="onCaseType(1000, item)"
              >{{ item.name }}</span
            >
          </div>
        </div>
        <div class="swiper_case_box">
          <swiper class="swiper_case" :options="caseSwiperOption1">
            <swiper-slide v-for="(item, index) of caseList" :key="index">
              <router-link :to="'/caseDetails/' + item.objId">
                <router-link :to="'/caseDetails/' + item.objId">
                  <img class="swiper_case_img" :src="item.headImg" />
                </router-link>
              </router-link>
            </swiper-slide>
          </swiper>
          <!-- 如果需要导航按钮 -->
          <div class="swp1 case-swiper-button case-swiper-button-prev"></div>
          <div class="swp1 case-swiper-button case-swiper-button-next"></div>
        </div>
      </div>
    </div>

    <div class="store_system_box">
      <div class="system_box">
        <div class="page_center">
          <div class="page_title white">
            多门店O2O体系 · MULTI-STORE O2O SYSTEM
          </div>
          <div class="description">
            是指多店铺入驻的平台商城系统，是以“商家入驻”为核心，实现微信端、PC端、APP端、小程序端、五网合一带分销代理订货功能，佣金结算，售后服务于一体。可同时实现平台自营和第三方销售两种运营模式，打通行业，商圈上下游供链的专业系统，是企业开展小京东，小天猫类的多商家入驻型电商业务的选择。
          </div>
          <div class="peculiarity_list">
            <div class="peculiarity_item">
              <img src="../assets/ic_business_a.png" alt="" />
              <span>多门店支持</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_b.png" alt="" />
              <span>门店独立收银</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_c.png" alt="" />
              <span>到店自取功能</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_d.png" alt="" />
              <span>外送体系</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_e.png" alt="" />
              <span>门店库存管理</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_d.png" alt="" />
              <span>同城配送功能</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_f.png" alt="" />
              <span>门店独立权限</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_g.png" alt="" />
              <span>线上线下数据统一</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_b.png" alt="" />
              <span>门店收银台</span>
            </div>
            <div class="peculiarity_item">
              <img src="../assets/ic_business_h.png" alt="" />
              <span>LBS门店定位</span>
            </div>
          </div>
        </div>
      </div>
      <div class="page_center">
        <div class="swiper_case_box">
          <swiper class="swiper_case" :options="caseSwiperOption2">
            <swiper-slide v-for="(item, index) of caseList" :key="index">
              <router-link :to="'/caseDetails/' + item.objId">
                <img class="swiper_case_img" :src="item.headImg" />
              </router-link>
            </swiper-slide>
            <!-- 如果需要分页器 -->
          </swiper>
          <div
            class="swp2 swiper-pagination case-swiper-pagination white"
            slot="pagination"
          ></div>
          <!-- 如果需要导航按钮 -->
          <div
            class="swp2 case-swiper-button white case-swiper-button-prev"
          ></div>
          <div
            class="swp2 case-swiper-button white case-swiper-button-next"
          ></div>
        </div>
      </div>
    </div>

    <div class="live_system_box">
      <div class="page_center">
        <div class="page_title">多样直播模式，加速直播变现</div>
        <div class="description">
          电商+直播+短视频+分销 让您抓住流量风口，轻松盈利
        </div>
      </div>
      <div class="page_center live_image">
        <img src="../assets/img_live.png" alt="" />
      </div>

      <div class="page_center">
        <div class="swiper_case_box">
          <swiper class="swiper_case" :options="caseSwiperOption3">
            <swiper-slide v-for="(item, index) of caseList" :key="index">
              <router-link :to="'/caseDetails/' + item.objId">
                <img class="swiper_case_img" :src="item.headImg" />
              </router-link>
            </swiper-slide>
            <!-- 如果需要分页器 -->
          </swiper>
          <div
            class="swp3 swiper-pagination case-swiper-pagination"
            slot="pagination"
          ></div>
          <!-- 如果需要导航按钮 -->
          <div class="swp3 case-swiper-button case-swiper-button-prev"></div>
          <div class="swp3 case-swiper-button case-swiper-button-next"></div>
        </div>
      </div>
    </div>

    <div class="group_buy_box">
      <div class="page_center">
        <div class="page_title">社区团购 · COMMUNITY GROUP BUYING</div>
        <div class="description">社区团购功能优势</div>
      </div>
      <div class="page_center group_buy_list">
        <div class="group_buy_item image0"></div>
        <div class="group_buy_item image1"></div>
        <div class="group_buy_item image2"></div>
        <div class="group_buy_item image3"></div>
        <div class="group_buy_item image4"></div>
        <div class="group_buy_item image5"></div>
      </div>
      <div class="page_center">
        <div class="swiper_case_box">
          <swiper class="swiper_case" :options="caseSwiperOption4">
            <swiper-slide v-for="(item, index) of caseList" :key="index">
              <router-link :to="'/caseDetails/' + item.objId">
                <img class="swiper_case_img" :src="item.headImg" />
              </router-link>
            </swiper-slide>
            <!-- 如果需要分页器 -->
          </swiper>
          <div
            class="swp4 swiper-pagination case-swiper-pagination"
            slot="pagination"
          ></div>
          <!-- 如果需要导航按钮 -->
          <div class="swp4 case-swiper-button case-swiper-button-prev"></div>
          <div class="swp4 case-swiper-button case-swiper-button-next"></div>
        </div>
      </div>
    </div>

    <div class="run_errands_box">
      <div class="min_width system_box">
        <div class="page_center">
          <div class="page_title white">跑腿 · RUN ERRANDS</div>
          <div class="description">
            代买/代取/代送/代排队，用心做好每一次服务
          </div>
        </div>
        <div class="page_center run_process_box">
          <div class="process_list">
            <div class="process_item">
              <div class="process_icon_box">
                <div class="process_icon image0"></div>
                <div class="txt title">打开跑腿</div>
              </div>
              <div class="txt content">APP、微信、小程序、PC官网均可下单</div>
            </div>
            <div class="process_item active">
              <div class="process_icon_box">
                <div class="process_icon image0"></div>
                <div class="txt title">下单支付费用</div>
              </div>
              <div class="txt content">为您推送订单等待抢单</div>
              <div class="icon_triangle right_out"></div>
              <div class="icon_triangle bottom_inner"></div>
            </div>
            <div class="process_item active">
              <div class="process_icon_box">
                <div class="process_icon image0"></div>
                <div class="txt title">选择跑腿业务</div>
              </div>
              <div class="txt content">代买、代送、代取、代排队</div>
              <div class="icon_triangle right_out"></div>
              <div class="icon_triangle top_inner"></div>
            </div>
            <div class="process_item">
              <div class="process_icon_box">
                <div class="process_icon image0"></div>
                <div class="txt title">输入服务地点</div>
              </div>
              <div class="txt content">备注您的特殊需求或服务详情</div>
            </div>
          </div>
          <div class="process_item last_item">
            <div class="process_icon_box">
              <div class="process_icon image0"></div>
              <div class="txt title">接单开始服务</div>
            </div>
            <div class="txt content">上门一对一专人服务</div>
          </div>
        </div>
      </div>
      <div class="page_center">
        <div class="swiper_case_box">
          <swiper class="swiper_case" :options="caseSwiperOption5">
            <swiper-slide v-for="(item, index) of caseList" :key="index">
              <router-link :to="'/caseDetails/' + item.objId">
                <img class="swiper_case_img" :src="item.headImg" />
              </router-link>
            </swiper-slide>
            <!-- 如果需要分页器 -->
          </swiper>
          <div
            class="swp5 swiper-pagination case-swiper-pagination white"
            slot="pagination"
          ></div>
          <!-- 如果需要导航按钮 -->
          <div
            class="swp5 case-swiper-button white case-swiper-button-prev"
          ></div>
          <div
            class="swp5 case-swiper-button white case-swiper-button-next"
          ></div>
        </div>
      </div>
    </div>

    <div class="page_center service_box">
      <div class="page_title">软件开发服务 · SOFTWARE DEVELOPMENT SERVICE</div>
      <p>
        　　深圳市腾创互联有限公司多年来在互联网行业深耕，积累了丰富的开发项目经验，目前已自主研发电商，房产，交友，直播等系统，为企业提供专业的APP、小程序、公众号、PC网站定制开发服务，拥有超过50人以上的开发团队，截止目前已服务超过300家公司企业。
      </p>
      <div class="service_support_box">
        <div class="service_support_item">
          <img src="../assets/ic_system_a.png" alt="" />
          <span>IOS APP 开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_b.png" alt="" />
          <span>Android APP 开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_c.png" alt="" />
          <span>PC 开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_d.png" alt="" />
          <span>供应链平台开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_e.png" alt="" />
          <span>直播系统开发</span>
        </div>
        <div class="service_support_item">
          <img src="../assets/ic_system_f.png" alt="" />
          <span>小程序商城开发</span>
        </div>
      </div>
    </div>

    <div class="news_box content_center">
      <div class="page_title white">行业资讯 · INDUSTRY INFORMATION</div>
      <div class="page_center">
        <div class="news_list_box">
          <div class="news_type">APP开发资讯</div>
          <router-link
            v-for="(item, index) of developmentNewsList"
            :key="index"
            class="news_list"
            :class="{ first: index == 0 }"
            :to="'/newsDetails/' + item.objId + '.html'"
          >
            <div v-if="index == 0" class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="news_title">{{ item.title }}</div>
              <div v-if="index == 0" class="description">
                {{ item.introduce }}
              </div>
              <div class="time">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
        <div class="news_list_box">
          <div class="news_type">电商运营资讯</div>
          <router-link
            v-for="(item, index) of operationNewsList"
            :key="index"
            class="news_list"
            :class="{ first: index == 0 }"
            :to="'/newsDetails/' + item.objId + '.html'"
          >
            <div v-if="index == 0" class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="news_title">{{ item.title }}</div>
              <div v-if="index == 0" class="description">
                {{ item.introduce }}
              </div>
              <div class="time">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
        <div class="news_list_box">
          <div class="news_type">APP运营技巧</div>
          <router-link
            v-for="(item, index) of skillNewsList"
            :key="index"
            class="news_list"
            :class="{ first: index == 0 }"
            :to="'/newsDetails/' + item.objId + '.html'"
          >
            <div v-if="index == 0" class="image">
              <img :src="item.headImg" :alt="item.title" />
            </div>
            <div class="info">
              <div class="news_title">{{ item.title }}</div>
              <div v-if="index == 0" class="description">
                {{ item.introduce }}
              </div>
              <div class="time">{{ item.createTime }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <z-contact></z-contact>
    <z-footer></z-footer>
  </div>
</template>
<script>
import zContact from "@/components/contact.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
    zContact,
  },
  data() {
    return {
      // 测试
      devTypeId: "",
      devTypeList: [
        {
          objId: "",
          name: "全部",
        },
      ],
      caseSwiperOption1: {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 20,
        navigation: {
          nextEl: ".case-swiper-button-next.swp1",
          prevEl: ".case-swiper-button-prev.swp1",
        },
      },
      caseSwiperOption2: {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination.swp2",
          clickable: true,
          bulletActiveClass: "white-bullet-active",
        },
        navigation: {
          nextEl: ".case-swiper-button-next.swp2",
          prevEl: ".case-swiper-button-prev.swp2",
        },
      },
      caseSwiperOption3: {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination.swp3",
          clickable: true,
        },
        navigation: {
          nextEl: ".case-swiper-button-next.swp3",
          prevEl: ".case-swiper-button-prev.swp3",
        },
      },
      caseSwiperOption4: {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination.swp4",
          clickable: true,
        },
        navigation: {
          nextEl: ".case-swiper-button-next.swp4",
          prevEl: ".case-swiper-button-prev.swp4",
        },
      },
      caseSwiperOption5: {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination.swp5",
          clickable: true,
          bulletActiveClass: "white-bullet-active",
        },
        navigation: {
          nextEl: ".case-swiper-button-next.swp5",
          prevEl: ".case-swiper-button-prev.swp5",
        },
      },

      caseList: [],
      developmentNewsList: [],
      operationNewsList: [],
      skillNewsList: [],
    };
  },
  created() {
    this.getNews();
    this.getClassifyData();
    this.getCaseList();
  },
  methods: {
    onPageJump(url) {
      this.$router.push(url);
    },

    // 获取项目类型
    getClassifyData() {
      this.$http.get("api/base_type/classify").then((res) => {
        let classifyData = res.data;
        this.devTypeList = this.devTypeList.concat(classifyData.devType);
        // this.platformList = this.platformList.concat(classifyData.platform);
        // this.industryList = this.industryList.concat(classifyData.industry);
      });
    },

    // 获取案例
    getCaseList() {
      // 案例
      let httpData = {
        pageNo: 1,
        pageSize: 12,
      };
      if (this.devTypeId) {
        httpData.devType = this.devTypeId;
      }
      this.$http.post("api/case/case_main", httpData).then((res) => {
        this.caseList = res.data.data;
      });
    },

    onCaseType(type, item) {
      if (type === 1000) {
        this.devTypeId = item.objId;
      } else if (type === 2000) {
        this.platformId = item.objId;
      } else if (type === 3000) {
        this.industryId = item.objId;
      }
      this.getCaseList();
    },

    // 新闻
    getNews() {
      // 新闻
      this.$http
        .post("api/article/v1/list", {
          pageNo: 1,
          pageSize: 5,
          type: 3003,
        })
        .then((res) => {
          this.developmentNewsList = res.data.data;
        });
      this.$http
        .post("api/article/v1/list", {
          pageNo: 1,
          pageSize: 5,
          type: 3004,
        })
        .then((res) => {
          this.operationNewsList = res.data.data;
        });
      this.$http
        .post("api/article/v1/list", {
          pageNo: 1,
          pageSize: 5,
          type: 3005,
        })
        .then((res) => {
          this.skillNewsList = res.data.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner_image_box {
  height: 660px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.e_commerce {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
  .case_title {
    font-size: 32px;
    color: #333333;
  }
  .case_info_box {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    p {
      width: 600px;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
    }
    .tab_box {
      display: flex;
      align-items: center;
      span {
        height: 32px;
        font-size: 14px;
        color: #333333;
        line-height: 32px;
        margin-right: 30px;
        padding: 0 12px;
        cursor: pointer;
        &.active {
          background-color: #222222;
          color: #fff;
        }
      }
    }
  }
}

.swiper_case_box {
  margin-top: 30px;
  height: 264px;
  position: relative;

  .swiper_case {
    padding-top: 10px;
  }

  .swiper_case_img {
    width: 100%;
    height: 264px;
    border-radius: 20px;
    cursor: pointer;

    transition: transform 0.4s;

    &:hover {
      box-shadow: 0px 1em 2em 0px rgba(48, 55, 66, 0.15);
      transform: translate(0, -10px);
    }
  }

  .case-swiper-pagination {
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;

    ::v-deep span {
      width: rem(20);
      background-color: #2f325a;
      border-radius: 0;
      height: rem(4);

      & + span {
        margin-left: 4px;
      }
    }

    ::v-deep .white-bullet-active {
      opacity: 1;
      background-color: #2f325a;
    }

    &.white {
      ::v-deep span {
        opacity: 0.2;
        width: rem(20);
        background-color: #fff;
        border-radius: 0;
        height: rem(4);

        & + span {
          margin-left: 4px;
        }
      }
      ::v-deep .white-bullet-active {
        opacity: 1;
        background-color: #fff;
      }
    }
  }

  .case-swiper-button {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #222222;
    cursor: pointer;

    &.white {
      background: #fff;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 12px;
      height: 12px;
      background: transparent;
      border: 2px solid transparent;
    }
  }

  .case-swiper-button-prev {
    left: -100px;

    &::after {
      margin-left: 6px;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(45deg);
      border-left-color: #fff;
      border-bottom-color: #fff;
    }

    &.white::after {
      border-left-color: #222222;
      border-bottom-color: #222222;
    }
  }

  .case-swiper-button-next {
    right: -100px;

    &::after {
      margin-left: -6px;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(-45deg);
      border-right-color: #fff;
      border-bottom-color: #fff;
    }

    &.white::after {
      border-right-color: #222222;
      border-bottom-color: #222222;
    }
  }
}

// O2O体系介绍
.store_system_box {
  padding-bottom: 84px;
  color: #fff;
  background: #161923;

  .system_box {
    height: 564px;
    padding-top: 80px;
    background-image: url("../assets/bg_business.png");
    background-position: center center;

    .description {
      margin: 40px auto 0 auto;
      width: 1000px;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }

    .peculiarity_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .peculiarity_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        margin-top: 60px;

        img {
          width: 30px;
          height: 30px;
        }

        span {
          font-size: 14px;
          color: #fff;
          margin-top: 24px;
        }
      }
    }
  }
}

.live_system_box {
  height: 1210px;
  padding: 80px 0;
  background: #fff;

  .description {
    margin-top: 40px;
    color: #333333;
    text-align: center;
  }
  .live_image {
    width: 1268px;
    height: 602px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.group_buy_box {
  height: 1089px;
  padding: 80px 0;
  background: #fff;
  .description {
    margin-top: 40px;
    text-align: center;
  }

  .group_buy_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 80px;

    .group_buy_item {
      width: 413px;
      height: 180px;
      padding-top: 28px;
      margin-top: 20px;
      border-radius: 8px;
      color: #fff;
      font-size: 14px;
      line-height: 28px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transition: background-size 0.4s;

      &:hover {
        background-size: 110% 110%;
      }

      &.image0 {
        background-image: url("../assets/ic_community_a.png");
      }
      &.image1 {
        background-image: url("../assets/ic_community_b.png");
      }
      &.image2 {
        background-image: url("../assets/ic_community_c.png");
      }
      &.image3 {
        background-image: url("../assets/ic_community_d.png");
      }
      &.image4 {
        background-image: url("../assets/ic_community_e.png");
      }
      &.image5 {
        background-image: url("../assets/ic_community_f.png");
      }
    }
  }
}

.run_errands_box {
  height: 1148px;
  background-color: #161923;

  .system_box {
    height: 760px;
    padding-top: 40p;
    background-image: url("../assets/bg_d.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .description {
      margin-top: 40px;
      color: #fff;
      text-align: center;
    }

    .run_process_box {
      display: flex;
      height: 480px;
      margin-top: 60px;

      .process_list {
        display: flex;
        flex-wrap: wrap;
        border-right: 1px solid rgba(42, 45, 52);
      }

      .process_item {
        flex-grow: 1;
        position: relative;
        width: 426px;
        height: 240px;
        padding-top: 54px;
        background-color: rgba(42, 45, 52, 0.8);
        font-size: 14px;

        &.active {
          opacity: 1;
          background-color: #ecac59;
        }

        .txt {
          color: #ffffff;
        }
        .title {
          font-size: 18px;
        }
        .content {
          opacity: 0.6;
          text-align: center;
        }

        .icon_triangle {
          z-index: 2;
          position: absolute;

          &.right_out {
            width: 18px;
            height: 24px;
            top: 50%;
            right: 0;
            transform: translate(100%, -50%);
            background-image: url("../assets/ic_triangle_e.png");
          }

          &.top_inner {
            width: 24px;
            height: 18px;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            background-image: url("../assets/ic_triangle_c_white.png");
          }

          &.bottom_inner {
            width: 24px;
            height: 18px;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0) rotate(180deg);
            background-image: url("../assets/ic_triangle_c_white.png");
          }
        }

        &.last_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 426px;
          height: 100%;
        }
      }

      .process_icon_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .process_icon {
          width: 30px;
          height: 30px;
          background-position: center center;
          margin-bottom: 20px;

          &.image0 {
            background-image: url("../assets/ic_run_a.png");
          }
          &.image0 {
            background-image: url("../assets/ic_run_b.png");
          }
          &.image0 {
            background-image: url("../assets/ic_run_b-1.png");
          }
          &.image0 {
            background-image: url("../assets/ic_run_c.png");
          }
          &.image0 {
            background-image: url("../assets/ic_run_e.png");
          }
        }
      }
    }
  }
}

.service_box {
  height: 553px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;

  p {
    margin-top: 40px;
    font-size: 14px;
    color: #333333;
    width: 854px;
    text-align: center;
    line-height: 28px;
  }
  .service_support_box {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    .service_support_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
      }
      span {
        font-size: 14px;
        color: #333333;
        margin-top: 24px;
      }
    }
  }
}

.news_box {
  background-color: #2a2d34;
  padding: 80px 0;
  .page_center {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .news_list_box {
      width: 370px;
      .news_type {
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 25px;
      }
      .news_list {
        display: flex;
        padding: 10px 0;
        cursor: pointer;
        &.first {
          padding-bottom: 45px;
          .image {
            display: flex;
          }
          .info {
            flex-direction: column;
            .news_title {
              width: 180px;
            }
            .description {
              display: flex;
              @include bov(4);
            }
            .time {
              text-align: right;
            }
          }
        }
        .image {
          width: 180px;
          height: 120px;
          flex-shrink: 0;
          margin-right: 10px;
          display: none;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          flex: 1;
          display: flex;
          justify-content: space-between;
          .news_title {
            font-size: 14px;
            color: #b5bcd9;
            @include toe();
            width: 260px;
            &:hover {
              color: #e0e6ff;
            }
          }
          .description {
            display: none;
            font-size: 12px;
            color: #b5bcd9;
            opacity: 0.4;
            width: 180px;
            line-height: 150%;
          }
          .time {
            font-size: 14px;
            color: #b5bcd9;
            opacity: 0.2;
          }
        }
      }
    }
  }
}

.page_title {
  font-size: 32px;
  color: #333333;
  text-align: center;
  &.white {
    color: #fff;
  }
}
</style>